import styled from 'styled-components';

export const StyledBlue1Hr = styled.hr`
    border: 0; 
    border-top: 4px solid var(--blue1);
    width: 100%;
    opacity: 1.0;
`;

export const StyledThinBlue1Hr = styled.hr`
    border: 0; 
    border-top: 1px solid var(--blue1);
    width: 100%;
    opacity: 1.0;
`;

export const StyledBasicHr = styled.hr`
    border: 0; 
    border-top: 1px solid #C8C8C8;
    width: 100%;
    opacity: 1.0;
`;