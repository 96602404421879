import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import CalendarModal from "./CalendarModal.jsx";
import CalendarList from "./CalendarList";
import './CalendarPage.css'
import dayjs from "dayjs";
import {fetchEvents, setSelectedDate, updateEvents} from "../../../redux/user_service/calendar/UserCalendarAction";
import {compareDate} from "../../../common/Date";
import {CalendarModel} from "../../../model/CalendarModel";
import SideBar from "../../../component/side_bar/SideBar";
import Footer from "../../../component/footer/Footer";
import SubCalendar from "./SubCalendar";

function CalendarPage() {
    const dispatch = useDispatch();

    const calendarSelector = useSelector((state) => state.calendar);
    const selectedDate = new Date(calendarSelector.selectedDate);
    const events = (function () {
        let events = calendarSelector.events;
        if (events === null) return {};

        events = JSON.parse(events);
        for (let key in events) {
            events[key] = events[key].map((json) => CalendarModel.fromJson(json));
        }
        return events;
    })();

    const [modal, setModal] = useState(false);
    const [newEvents, setNewEvents] = useState([{title: '', startTime: null, endTime: null}]);
    const [alert, setAlert] = useState(false);

    const handleDateClick = (value) => {
        if (compareDate(selectedDate, value) !== 0) {
            dispatch(setSelectedDate(value));
        }
        else {
            const formattedDate = moment(value).format('ddd, D MMM');
            const existingEvents = events[formattedDate]
                || [new CalendarModel({})];

            setNewEvents(existingEvents);
            setModal(true);
            setAlert(false);
        }
    };

    const handleSave = () => {
        const eventsWithTimes = newEvents.map(event => ({
            ...event,
            startTime: event.startTime ? dayjs(event.startTime).toISOString() : null,
            endTime: event.endTime ? dayjs(event.endTime).toISOString() : null,
        }));

        // 해당 날짜에 새로운 이벤트를 덮어씌움
        dispatch(updateEvents({ [moment(selectedDate).format('ddd, D MMM')]: eventsWithTimes }));

        setModal(false);
        setNewEvents([{ title: '', startTime: null, endTime: null }]);
    };

    const handleAddInput = () => {
        setNewEvents([...newEvents, { title: '', startTime: null, endTime: null }]);
    };

    const handleEventChange = (index, field, value) => {
        const updatedEvents = [...newEvents];
        const updatedEvent = { ...updatedEvents[index] };
        updatedEvent[field] = field.includes('Time') ? dayjs(value).toISOString() : value;
        updatedEvents[index] = updatedEvent;
        setNewEvents(updatedEvents);
    };

    useEffect(() => {
        dispatch(fetchEvents());
    }, [dispatch]);

    return (
        <div className="screen" style={{display: "flex", flexDirection: "row"}}>
            <SideBar
                width="calc(480 * var(--bs-aspect-ratio-width))"
                padding="
                calc(240 * var(--bs-aspect-ratio-height)) 0
                calc(100 * var(--bs-aspect-ratio-height)) 0">
                <SubCalendar events={events}/>
            </SideBar>

            <div style={{
                width: "calc(1032 * var(--bs-aspect-ratio-width))",
                height: "100%",
                display: "flex",
                paddingTop: "calc(240 * var(--bs-aspect-ratio-height))",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <Calendar
                    locale="en-US"
                    value={selectedDate}
                    next2Label={null}
                    prev2Label={null}
                    showNeighboringMonth={false}
                    tileContent={({date}) => <CalendarList events={events} date={date}/>}
                    onClickDay={handleDateClick}
                />

                <Footer sidePosition/>
            </div>

            {alert && <div>{/* alert 메시지 추후에 넣을 예정,, */}</div>}
            {modal && (
                <CalendarModal
                    selectedDate={selectedDate}
                    newEvents={newEvents}
                    handleEventChange={handleEventChange}
                    handleAddInput={handleAddInput}
                    handleSave={handleSave}
                    handleClose={() => setModal(false)}
                />
            )}
        </div>
    );
}

export default CalendarPage;
