import {Route, Routes} from "react-router-dom";
import {AdminRoute} from "./AdminRoute";
import HomePage from "../view/user_viewer/home_page/HomePage";
import UserViewer from "../view/user_viewer/UserViewer";
import AttendancePage from "../view/user_viewer/attendance_page/AttendancePage";
import CalendarPage from "../view/user_viewer/calendar_page/CalendarPage";
import NewsListPage from "../view/user_viewer/news_page/NewsListPage";
import NewsEditorPage from "../view/user_viewer/news_page/NewsEditorPage";
import NewsViewPage from "../view/user_viewer/news_page/NewsViewPage";

export function GeneralRoute() {
    return(
        <Routes>
            <Route path="/" element={<UserViewer/>}>
                <Route index path="" element={<HomePage/>}/>
                <Route path="/attendance">
                    <Route path="" element={<AttendancePage/>}/>
                    <Route path="history" element={<div>출석 기록 페이지</div>}/>
                </Route>
                <Route path="/calendar">
                    <Route index element={<CalendarPage/>}/>
                    <Route path=":id" element={<div>일정 상세 페이지</div>}/>
                </Route>
                <Route path="/login" element={<div>로그인 페이지</div>}/>
                <Route path="/member" element={<div>멤버 페이지</div>}/>
                <Route path="/me" element={<div>마이 페이지</div>}/>
                <Route path="/news">
                    <Route path="" element={<NewsListPage/>}/>
                    <Route path="edit" element={<NewsEditorPage/>}/>
                    <Route path=":id" element={<NewsViewPage/>}/>
                </Route>
            </Route>
            <Route path="/admin/*" element={<AdminRoute/>}/>
        </Routes>
    );
}