
export class UserModel {
    constructor({uid, name, studentId, major, phone, email, role, unit, permission, point }) {
        this.uid = uid ?? null;
        this.name = name ?? null;
        this.studentId = studentId ?? null;
        this.major = major ?? null;
        this.phone = phone ?? null;
        this.email = email ?? null;
        this.role = role ?? null;
        this.unit = unit ?? null;
        this.permission = permission ?? null;
        this.point = point ?? null;
    }

    static fromJson(json) {
        let obj = JSON.parse(json);
        return new UserModel({
            uid: obj.uid ?? null,
            name: obj.name ?? null,
            studentId: obj.studentId ?? null,
            major: obj.major ?? null,
            phone: obj.phone ?? null,
            email: obj.email ?? null,
            role: obj.role ?? null,
            unit: obj.unit ?? null,
            permission: obj.permission ?? null,
            point: obj.point ?? null
        });
    }

    toJson() {
        let obj = {
            uid: this.uid,
            name: this.name,
            studentId: this.studentId,
            major: this.major,
            phone: this.phone,
            email: this.email,
            role: this.role,
            unit: this.unit,
            permission: this.permission,
            point: this.point
        };
        return JSON.stringify(obj);
    }

    copyWith({ name, studentId, major, phone, email, role, unit, permission, point }) {
        return new UserModel({
            uid: this.uid,
            name: name ?? this.name,
            studentId: studentId ?? this.studentId,
            major: major ?? this.major,
            phone: phone ?? this.phone,
            email: email ?? this.email,
            role: role ?? this.role,
            unit: unit ?? this.unit,
            permission: permission ?? this.permission,
            point: point ?? this.point
        });
    }

    isAdmin() {
        return this.permission === "admin";
    }
}