import React from "react";
import "./HomePage.css";
import styled from "styled-components";
import Footer from "../../../component/footer/Footer";

const StyledRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex: ${props => props.flex || null};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

function HomePage() {
    return (
        <div className="main-calligraphy-div">
            <StyledRow>
                <h1 className="title bruno-ace-regular">
                    AIIA
                </h1>
                <p className="title-meaning bruno-ace-regular">
                    Artificial<br/>
                    Intelligence<br/>
                    In Actions
                </p>
            </StyledRow>

            <Footer/>
        </div>
    );
}

export default HomePage;