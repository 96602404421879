const names = [
    'Aatrox', 'Ahri', 'Akali', 'Alistar', 'Amumu', 'Anivia', 'Annie', 'Aphelios', 'Ashe', 'Aurelion Sol',
    'Azir', 'Bard', 'Blitzcrank', 'Brand', 'Braum', 'Caitlyn', 'Camille', 'Cassiopeia', 'Cho\'Gath', 'Corki',
    'Darius', 'Diana', 'Dr. Mundo', 'Draven', 'Ekko', 'Elise', 'Evelynn', 'Ezreal', 'Fiddlesticks', 'Fiora',
    'Fizz', 'Galio', 'Gangplank', 'Garen', 'Gnar', 'Gragas', 'Graves', 'Gwen', 'Hecarim', 'Heimerdinger',
    'Illaoi', 'Irelia', 'Ivern', 'Janna', 'Jarvan IV', 'Jax', 'Jayce', 'Jhin', 'Jinx', 'Kai\'Sa', 'Kalista',
    'Karma', 'Karthus', 'Kassadin', 'Katarina', 'Kayle', 'Kayn', 'Kennen', 'Kha\'Zix', 'Kindred', 'Kled',
    'Kog\'Maw', 'LeBlanc', 'Lee Sin', 'Leona', 'Lillia', 'Lissandra', 'Lucian', 'Lulu', 'Lux', 'Malphite',
    'Malzahar', 'Maokai', 'Master Yi', 'Miss Fortune', 'Mordekaiser', 'Morgana', 'Nami', 'Nasus', 'Nautilus',
    'Neeko', 'Nidalee', 'Nocturne', 'Nunu & Willump', 'Olaf', 'Orianna', 'Ornn', 'Pantheon', 'Poppy', 'Pyke',
    'Qiyana', 'Quinn', 'Rakan', 'Rammus', 'Rek\'Sai', 'Rell', 'Renekton', 'Rengar', 'Riven', 'Rumble', 'Ryze',
    'Samira', 'Sejuani', 'Senna', 'Seraphine', 'Sett', 'Shaco', 'Shen', 'Shyvana', 'Singed', 'Sion', 'Sivir',
    'Skarner', 'Sona', 'Soraka', 'Swain', 'Sylas', 'Syndra', 'Tahm Kench', 'Taliyah', 'Talon', 'Taric', 'Teemo',
    'Thresh', 'Tristana', 'Trundle', 'Tryndamere', 'Twisted Fate', 'Twitch', 'Udyr', 'Urgot', 'Varus', 'Vayne',
    'Veigar', 'Vel\'Koz', 'Vex', 'Vi', 'Viego', 'Viktor', 'Vladimir', 'Volibear', 'Warwick', 'Wukong', 'Xayah',
    'Xerath', 'Xin Zhao', 'Yasuo', 'Yone', 'Yorick', 'Yuumi', 'Zac', 'Zed', 'Ziggs', 'Zilean', 'Zoe', 'Zyra'
];

const departments = [
    "존잘 전공", "존못 전공", "개그 전공", "요리 전공",
    "도박 전공", "꽐라 전용", "인성 전공", "씹덕 전공"
];

const roles = [ "member", "admin" ];

const Unit = {
    flutter: "Flutter",
    react: "React",
    spring: "Spring",
    ai: "AI R&D"
}

const length = 2;

const UserSample = Array.from({length: length}, (_, index) => {
    let randomNumber = Math.floor(Math.random() * length * 4);

    let name = names[randomNumber % names.length];
    let department = departments[randomNumber % departments.length];
    let studentId = Math.floor(Math.random() * 100);
    let role = roles[randomNumber % roles.length];
    let unit = Unit[Object.keys(Unit)[randomNumber % Object.keys(Unit).length]];
    let permission = [
        randomNumber % 2 === 0,
        randomNumber % 3 === 0,
        randomNumber % 5 === 0,
    ];
    let point = Math.floor(Math.random() * 100) * 1000;

    return {
        id: index,
        name: name,
        studentId: studentId,
        department: department,
        role: role,
        unit: unit,
        permission: permission,
        point: point
    };
});

export { UserSample };