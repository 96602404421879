import React from 'react';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {Col, Container, Row} from 'react-bootstrap';

const EventModal = ({selectedDate, newEvents, handleEventChange, handleAddInput, handleSave, handleClose}) => {
    return (<div className="modal">
        <div className="modal-content">
            <div className="modal-txt"
                 style={{color: 'var(--sub-title5)', marginBottom: '46px', marginTop: '64px'}}>
                {dayjs(selectedDate).locale('en').format('ddd, D MMM')}
            </div>
            {newEvents.map((event, index) => (<div key={index}>
                <Row>
                    <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
                        <button className="modal-plus-button" onClick={handleAddInput}>+</button>
                    </Col>
                    <Col xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={event.title}
                            onChange={(e) => handleEventChange(index, 'title', e.target.value)}
                            placeholder={`일정 제목 ${index + 1}`}
                        />
                    </Col>

                </Row>
                <Container>
                    <Row style={{alignItems: 'center'}}>
                        <Col xs="auto" style={{ marginLeft:"65px" }}>
                            <TimePickerComponent
                                label="Start Time"
                                onChange={(newValue) => handleEventChange(index, 'startTime', newValue)}
                            />
                        </Col>
                        <Col xs="auto">
                            <div
                                style={{
                                    width: "54px",
                                    borderBottom: "2px solid",
                                    marginLeft:'32px',
                                    marginRight:'10px',
                                    color: "var(--line1)",
                                }}
                            ></div>
                        </Col>
                        <Col xs="auto">
                            <TimePickerComponent
                                label="End Time"
                                onChange={(newValue) => handleEventChange(index, 'endTime', newValue)}
                            />
                        </Col>

                    </Row>
                </Container>

            </div>))}
            <Row className="justify-content-end" style={{ position: 'absolute', bottom: '0px', right: '0px', width: '100%' }}>
                <Col xs="auto" className="d-flex justify-content-end">
                    <button className='modal-button' style={{backgroundColor: 'white', color: 'var(--airblue)', marginRight: '10px'}}
                            onClick={handleClose}>취소
                    </button>
                    <button className='modal-button' style={{backgroundColor: 'var(--airblue)', color: 'white'}}
                            onClick={handleSave}>저장
                    </button>
                </Col>
            </Row>

        </div>
    </div>);
};

const TimePickerComponent = ({label, onChange}) => {
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
            label={label}
            onChange={onChange}
            renderInput={(props) => <input {...props} />}
        />
    </LocalizationProvider>);
};

export default EventModal;