import React from "react";
import TopBar from "../../component/top_bar/TopBar";
import {Outlet} from "react-router-dom";

function UserViewer() {
    return (
        <div className="screen">
            <TopBar isAdminPage={false}/>

            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <Outlet/>
            </div>
        </div>
    );
}

export default UserViewer;