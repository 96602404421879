import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';

import '@toast-ui/editor/dist/i18n/ko-kr';
import {useRef} from "react";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {StyledBlue1Hr} from "./NewsComponents";
import {useNavigate} from "react-router-dom";

function NewsEditorPage() {
    const editorRef = useRef();
    const navigate = useNavigate();

    const handleBack = () => navigate("/news");

    return (<Box sx={{
        width: '100vw', height: '100vh',
        display: "flex", justifyContent: "center", alignItems: "center",
    }}>
        <Box sx={{width: "1042px", height: "560px", marginTop: "45px"}}>

            <strong style={{fontSize: "21px", textAlign: "left"}}>전달하고 싶은 소식을 작성해주세요 !</strong>

            <StyledBlue1Hr style={{marginBottom: "24px"}}></StyledBlue1Hr>

            <TextField sx={{'& .MuiOutlinedInput-root': {height: "52px", borderRadius: "8px"}, marginBottom: "24px"}}
                       id="outlined-basic"
                       variant="outlined"
                       fullWidth
                       placeholder="멋진 제목을 지어주세요 !"
            />

            <Editor
                initialValue="# 결국은, AIIA."
                previewStyle="vertical"
                initialEditType="markdown"
                toolbarItems={[
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task'],
                    ['table', 'image', 'link'],
                    ['code', 'codeblock'],
                    ['scrollSync'],
                ]}
                hideModeSwitch={true}
                useCommandShortcut={true}
                usageStatistics={false}
                ref={editorRef}

                language="ko-KR"

                sx={{'& .toastui-editor-defaultUI': {borderRadius: '8px', overflow: 'hidden'}}}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: "15px"}}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "var(--airblue)",
                        width: "80px", height: "45px",
                        borderRadius: "8px",
                        fontSize: "18px", fontFamily: "Pretendard-Regular"
                    }}
                    onClick={handleBack}
                >
                    등록
                </Button>
            </Box>

        </Box>


    </Box>);
}

export default NewsEditorPage;