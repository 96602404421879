import {compareDate} from "../../common/Date";

function AdminAttendanceCalendarTileContent(date, view, counts) {
  if (view !== 'month' || compareDate(date) > 0) return null;

  return (
      <div className="calendar-cell body2-txt">
          {counts[date.getDate()-1].value}명
      </div>
  )
}

function UserAttendanceCalendarTileContent(date, view, statistics) {
    if (view !== 'month' || compareDate(date) > 0) return null;

    let data = statistics[date.getDate()-1];

    return (
        <div className="calendar-cell body2-txt">
            {
                data === 0 ? "출석" :
                data === 1 ? "지각" :
                data === 2 ? "결석" : ""
            }
        </div>
    )
}

function ScheduleCalendarTileContent({ date, value }) {
  return (
    <div className="calendar-cell">
      <span>{date}</span>
      <span>{value}</span>
    </div>
  );
}

export { AdminAttendanceCalendarTileContent, UserAttendanceCalendarTileContent, ScheduleCalendarTileContent };