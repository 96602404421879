export function getLastDayOfMonth(date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let lastDate = new Date(year, month+1, 0);
    lastDate.setDate(lastDate.getDate() - 1);
    return date.getDay() === lastDate.getDay();
}

export function compareDate(date, date2) {
    let diff = date.getTime() - (date2 ?? new Date().getTime());
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (diff === 0) return 0;
    if (diff > 0) return 1;
    return -1;
}