import React from 'react';
import moment from 'moment';
import {Col} from 'react-bootstrap';
import styled from 'styled-components';
import "./CalendarModal.jsx"

const SubCalendarContainer = styled.div`
    margin-left: calc(108 * var(--bs-aspect-ratio-width));
    margin-bottom: calc(80 * var(--bs-aspect-ratio-height));
    border-left: 3px solid var(--blue1);
    padding-left: 30px;
`;

function SubCalendar({events}) {

    const startDate = moment().startOf('week');
    const weekCalendar = [...Array(7)]
        .map((_, i) => startDate.clone().add(i, 'days'));

    return (
        <Col>
            {weekCalendar.map((day, index) => (
                <SubCalendarContainer key={index}>
                    <div className="btn-txt" style={{ color: 'var(--sub-title5)' }}>
                        {day.format('ddd, D MMM')}
                    </div>
                    <div style={{ marginTop: 'calc(40 * var(--bs-aspect-ratio-height))' }}>
                        {(events[day.format('ddd, D MMM')] || []).map((event, idx) => (
                            <div className="sub-calendar-txt" style={{ color: 'var(--sub-title5)' }} key={idx}>
                                {event.title}
                                <div className="caption-txt" style={{color: 'var(--airblue)'}}>
                                    {event.startTime && event.endTime
                                        ? `${moment(event.startTime).format('HH:mm')} - ${moment(event.endTime).format('HH:mm')}`
                                        : '시간 미정'}

                                </div>
                            </div>
                        ))}
                    </div>
                </SubCalendarContainer>
            ))}
        </Col>
    );
}

export default SubCalendar;
