import React, { useState } from "react";
import {Col, Form, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";

function TempTextInput({ label, value, onChange }) {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{label}</InputGroup.Text>
            <Form.Control
                placeholder={label}
                aria-label={label}
                aria-describedby="basic-addon1"
                value={value}
                onChange={onChange}
            />
        </InputGroup>
    );
}

function TempSelectInput({ label, items, value, onChange }) {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{label}</InputGroup.Text>
            <Form.Select aria-label={label} value={value} onChange={onChange}>
                <option value="">선택</option>
                {items.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))}
            </Form.Select>
        </InputGroup>
    );
}

function UserRegisterPage() {
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [name, setName] = useState("");
    const [studentId, setStudentId] = useState("");
    const [department, setDepartment] = useState("");
    const [role, setRole] = useState("");
    const [unit, setUnit] = useState("");
    const [permissions, setPermissions] = useState({
        attendance: false, schedule: false, news: false,
    });

    const roleOptions = ["신입 부원", "정규 부원", "임원진", "부회장", "회장"];
    const unitOptions = ["Flutter", "React", "Spring", "AI R&D"];
    const permissionOptions = ["출석", "일정", "뉴스"];

    const handlePermissionChange = (e) => {
        let { value, checked } = e.target;
        switch (value) {
            case "출석":
                value = "attendance";
                break;
            case "일정":
                value = "schedule";
                break;
            case "뉴스":
                value = "news";
                break;
            default:
                break;
        }
        setPermissions({
            ...permissions,
            [value.toLowerCase()]: checked,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            id,
            pw,
            name,
            studentId,
            department,
            role,
            unit,
            permissions,
        });
    };

    return (
        <Col style={{ width: "60%" }}>
            <h2>회원 등록</h2>

            <form onSubmit={handleSubmit}>
                <TempTextInput label="ID" value={id} onChange={(e) => setId(e.target.value)}/>
                <TempTextInput label="PW" value={pw} onChange={(e) => setPw(e.target.value)}/>
                <TempTextInput label="이름" value={name} onChange={(e) => setName(e.target.value)}/>
                <TempTextInput label="학번" value={studentId} onChange={(e) => setStudentId(e.target.value)}/>
                <TempTextInput label="학과" value={department} onChange={(e) => setDepartment(e.target.value)}/>

                <TempSelectInput label="역할" items={roleOptions} value={role} onChange={(e) => setRole(e.target.value)}/>
                <TempSelectInput label="유닛" items={unitOptions} value={unit} onChange={(e) => setUnit(e.target.value)}/>

                <InputGroup key={`inline-checkbox`} className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                    <InputGroup.Text id="basic-addon1">권한</InputGroup.Text>
                    {permissionOptions.map((permission, index) => (
                        <Form.Check
                            key={index}
                            inline
                            label={permission}
                            value={permission}
                            type="checkbox"
                            checked={permissions[permission.toLowerCase()]}
                            onChange={handlePermissionChange}
                        />
                    ))}
                </InputGroup>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </form>
        </Col>
    );
}

export default UserRegisterPage;