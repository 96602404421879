import React from "react";
import TableCell from "@mui/material/TableCell";

export function rowContent(_index, row, attributes, setContent) {
    return (
        <React.Fragment>
            {attributes.map((attribute) => (
                <TableCell key={attribute.dataKey} align="center">
                    {setContent(_index, row, attribute)}
                </TableCell>
            ))}
        </React.Fragment>
    );
}