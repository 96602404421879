import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {NewsModel} from "../../../model/NewsModel";

const setPage = createAction('news/setPage');

const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async (action) => {
        try {
            console.log("[News] action: fetchNews (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise((resolve) => setTimeout(resolve, 2000));

            let result = [];
            for (let i = 0; i < 50; i++) {
                let randViewCount = Math.floor(Math.random() * 100);
                let date = new Date();

                let obj = new NewsModel({
                    uid: i,
                    title: `임시 소식 ${i}`,
                    author: "관리자",
                    content: null,
                    createdBy: date,
                    updatedBy: date,
                    viewCount: randViewCount
                });
                result.push(obj.toJson());
            }

            console.log(`[News] action: fetchNews (done)`);
            return result;
        } catch (e) {
            console.error("[News] action: fetchNews (error)", e);
            console.log(e);
        }
    }
)

const addNews = createAsyncThunk(
    'news/addNews',
    async (action) => {
        try {
            console.log("[News] action: addNews (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise((resolve) => setTimeout(resolve, 2000));

            console.log("[News] action: addNews (done)");
            return action;
        } catch (e) {
            console.error("[News] action: addNews (error)", e);
            console.log(e);
        }
    }
);

export {
    setPage,
    fetchNews,
    addNews
}