
import {createSlice} from "@reduxjs/toolkit";
import {ActionType} from "../../_ActionType";
import {addEvent, fetchEvents, setSelectedDate, updateEvents} from "./UserCalendarAction";
import moment from "moment";

export function UserCalendarReducer() {
    return createSlice({
        name: 'calendar',
        initialState: {
            selectedDate: (new Date()).toString(),
            events: null,
            status: ActionType.IDLE,
            error: null
        },
        // 비동기 작업 및 외부 액션에 대한 리듀서
        extraReducers: (builder) => {
            // setSelectedDate
            builder
                .addCase(setSelectedDate, (state, action) => {
                    state.selectedDate = action.payload;
                });

            // fetchEvents
            builder
                .addCase(fetchEvents.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchEvents.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.events = action.payload;
                })
                .addCase(fetchEvents.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            // addEvent
            builder
                .addCase(addEvent.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(addEvent.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    const { date, newEvent } = action.payload;
                    const formattedDate = moment(date).format('ddd, D MMM');
                    state.events[formattedDate] = [
                        ...(state.events[formattedDate] || []),
                        newEvent,
                    ];
                })
                .addCase(addEvent.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            // updateEvents
            builder
                .addCase(updateEvents.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(updateEvents.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.events = { ...state.events, ...action.payload };
                })
                .addCase(updateEvents.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
        }
    });
}