
export class CalendarModel {
    constructor({uid, title, start, end}) {
        this.uid = uid ?? null;
        this.title = title ?? null;
        this.start = start ?? null;
        this.end = end ?? null;
    }

    static fromJson(json) {
        let obj = JSON.parse(json);
        return new CalendarModel({
            uid: obj.uid,
            title: obj.title,
            start: new Date(obj.start),
            end: new Date(obj.end)
        });
    }

    toJson() {
        let obj = {
            uid: this.uid,
            title: this.title,
            start: this.start.toString(),
            end: this.end.toString()
        }
        return JSON.stringify(obj);
    }

    copyWith({ title, start, end }) {
        return new CalendarModel({
            uid: this.uid,
            title: title ?? this.title,
            start: start ?? this.start,
            end: end ?? this.end
        });
    }
}