import React, {useState} from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";

function DropdownChip({
    hint,
    init,
    items,
    onClick
}) {
    const [selectedIndex, setSelectedIndex] = useState(init || -1);

    const handleClick = (e) => {
        let index = e.target.id;
        setSelectedIndex(index);
        onClick(index);
    };

    return (
        <DropdownButton
            title={selectedIndex >= 0 ? items[selectedIndex] : hint}
            style={{
                // padding: "6px 18px",
                // borderRadius: "30px",
                // fontSize: "14px",
                // fontWeight: 500,
                // color: selectedIndex >= 0 ? items[selectedIndex].fontColor : "black",
                // backgroundColor: selectedIndex >= 0 ? items[selectedIndex].backgroundColor : "var(--bs-gray-light)",
            }}
        >
            {
                items.map((item, index) => (
                    <Dropdown.Item
                        id={index}
                        className="profile-modal-category-txt"
                        onClick={handleClick}
                    >
                        {item}
                    </Dropdown.Item>
                ))
            }
        </DropdownButton>
    );
}

export default DropdownChip;