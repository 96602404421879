import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Viewer} from '@toast-ui/react-editor';

import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import {StyledBlue1Hr, StyledThinBlue1Hr, StyledBasicHr} from "./NewsComponents";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate} from "react-router-dom";

const TestString = `
### 📢 회식도 동아리 활동의 연장이다!

지난 금요일, 동아리 회식이 성황리에 마무리되었습니다. 회식은 동아리 활동의 연장선으로, 모든 구성원이 한자리에 모여 친목을 다지는 중요한 시간이었죠.

> "회식도 동아리 활동의 연장이다."  
> - **기장 김한백**, 2024년 9월 초 개강 총회에서

다들 즐거운 시간을 보내며 동아리 활동의 또 다른 면모를 확인할 수 있었습니다.

---

### 🔥 AFAC 2403

이번 학기에는 2403 부원들의 **AFAC**가 예정되어 있습니다. 각 유닛 수습 부원들은 다음의 항목에 집중해주시기 바랍니다:

- **포인트 1**: 기획, 제작 뿐만 아니라 배포까지가 플랫폼 제작이다.
- **포인트 2**: 지속 가능한 프로젝트를 기획하자.
- **포인트 3**: 기간 내에 끝낼 수 있는 규모를 계획하자. 

---

### 🛠️ 개발자 노트

다음은 **JavaScript 코드**의 예시입니다.

\`\`\`javascript
// 2024 AIIA 캐치프레이즈
console.log('결국은, AIIA.');
\`\`\`
`;

const NewsInfo = ({author, date, views}) => (
    <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Typography style={{color: 'var(--airblue)', marginRight: '10px'}}>작성자</Typography>
        <Typography style={{color: 'var(--dark-grey)', marginRight: '15px'}}>{author}</Typography>
        <Typography style={{color: 'var(--airblue)', marginRight: '10px'}}>작성일</Typography>
        <Typography style={{color: 'var(--dark-grey)', marginRight: '15px'}}>{date}</Typography>
        <Typography style={{color: 'var(--airblue)', marginRight: '10px'}}>조회수</Typography>
        <Typography style={{color: 'var(--dark-grey)'}}>{views}</Typography>
    </Box>
);

function NewsViewPage() {
    const navigate = useNavigate();

    const handleBack = () => navigate("/news");

    return (<Box sx={{
        width: '100vw', height: '100vh',
        display: "flex", justifyContent: "center", alignItems: "center",
    }}>
        <Box sx={{width: "1042px", height: "560px", marginTop: "45px"}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <strong style={{fontSize: "32px", fontFamily: "Pretendard-Regular"}}>
                    이거슨 제목입니다 ~
                </strong>
            </div>

            <StyledBlue1Hr style={{marginBottom: "10px"}}/>

            <NewsInfo author="김철수" date="2024.02.10" views={16}/>

            <StyledBasicHr style={{marginTop: "10px"}}/>

            <Viewer initialValue={TestString}/>

            <StyledThinBlue1Hr/>

            <Box
                style={{display: 'flex', justifyContent: 'flex-end'}}
                onClick={handleBack}
            >
                <strong style={{fontSize: "18px", fontFamily: "Pretendard-Regular", marginRight: "10px"}}>
                    목록으로 돌아가기
                </strong>

                <ArrowForwardIosIcon/>
            </Box>

        </Box>

    </Box>);
}

export default NewsViewPage;