import Button from "react-bootstrap/Button";
import {useState} from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const StyledCode = styled.p`
    font-family: 'Pretendard', sans-serif;
    font-weight: revert;
    font-size: 80px;
    margin: calc(16 * var(--bs-aspect-ratio-height)) 0;
`;

function AttendanceCodeGeneratorPage() {
    const [code, setCode] = useState(null);
    const navigator = useNavigate();

    // useEffect(() => {
    //
    // }, [code]);

    // 숫자로 이루어진 6자리 코드 생성후 setCode
    const handleGenerateCode = () => {
        let code = Math.floor(Math.random() * 1000000);
        code = code.toString().padStart(6, '0');
        setCode(code);
    };

    const navigateToDailyAttendancePage = () => {
        let formattedDate = dayjs(new Date()).format("YYYYMMDD");
        navigator("/admin/attendance/calendar/daily?date=" + formattedDate);
    };

    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Button
                variant="primary"
                // disabled={code !== null}
                onClick={handleGenerateCode}>
                출석코드 생성
            </Button>
            <StyledCode>
                {code || "_ _ _ _ _ _"}
            </StyledCode>
            <Button
                variant="primary"
                onClick={navigateToDailyAttendancePage}>
                오늘 출석 현황 확인
            </Button>
        </div>
    );
}

export default AttendanceCodeGeneratorPage;