import React from 'react';
import './TopBar.css';
import {Container, Nav, Navbar} from "react-bootstrap";
import {ImageAssets} from "../../common/Image";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {tryLogin, tryLogout} from "../../redux/user_service/user/UserAction";
import {UserModel} from "../../model/UserModel";

function TopBar({ isAdminPage }) {
    const dispatch = useDispatch();
    const userSelector = useSelector(state => state.user);

    const userData = userSelector.data === null
        ? null
        : UserModel.fromJson(userSelector.data);

    const handleLogInOut = async () => {
        if (userData !== null) {
            await dispatch(tryLogout());
        } else {
            await dispatch(tryLogin());
        }
    }

    return (
        <Navbar className="TopNav">
            <Container className="TopNav-Content">
                <Navbar.Brand as={Link} to={isAdminPage ? "/admin/attendance/calendar" : '/'}>
                    <img className="Logo"
                         src={ImageAssets.logo_aiia}
                         alt="logo"
                         width="100px"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="body2-txt">
                    {
                        isAdminPage
                            ? <>
                                <Nav className="Menu">
                                    <img
                                        src={ImageAssets.icon_admin}
                                        alt="admin"
                                        width="40px"
                                        style={{ marginLeft: 16 }}
                                    />
                                    <Nav className="Text" style={{
                                        color: "var(--airblue)",
                                        marginLeft: 16,
                                    }}>
                                        관리자 페이지
                                    </Nav>
                                </Nav>
                                <Nav className="Actions">
                                    <Nav.Link as={Link} className="Text" to="/">
                                        Main Home
                                    </Nav.Link>
                                </Nav>
                            </>
                            : <>
                                <Nav className="Menu">
                                    <Nav.Link as={Link} className="Text" to="/member">멤버정보</Nav.Link>
                                    <Nav.Link as={Link} className="Text" to="/news">소식광장</Nav.Link>
                                    {
                                        userData !== null &&
                                        <>
                                            <Nav.Link as={Link} className="Text" to="/calendar">일정관리</Nav.Link>
                                            <Nav.Link as={Link} className="Text" to="/attendance">출석체크</Nav.Link>
                                        </>
                                    }
                                </Nav>
                                <Nav className="Actions">
                                    <Nav className="Text" onClick={handleLogInOut}>
                                        {userData !== null ? "로그아웃" : "로그인"}
                                    </Nav>
                                    {
                                        userData !== null &&
                                        <Nav.Link as={Link} className="Text" to="/me">
                                            <img src={ImageAssets.icon_profile} alt="profile" width="40px"/>
                                        </Nav.Link>
                                    }
                                    {
                                        userData !== null && userData.isAdmin() &&
                                        <Nav.Link as={Link} className="Text" to="/admin/attendance/calendar">
                                            <img src={ImageAssets.icon_admin} alt="admin" width="40px"/>
                                        </Nav.Link>
                                    }
                                </Nav>
                            </>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopBar;