
export class NewsModel {
    constructor({ uid, title, author, content, createdBy, updatedBy, viewCount }) {
        this.uid = uid ?? null;
        this.title = title ?? null;
        this.author = author ?? null;
        this.content = content ?? null;
        this.createdBy = createdBy ?? null;
        this.updatedBy = updatedBy ?? null;
        this.viewCount = viewCount ?? null;
    }

    static fromJson(json) {
        let obj = JSON.parse(json);
        let createdBy = obj.createdBy ? new Date(obj.createdBy) : null;
        let updatedBy = obj.updatedBy ? new Date(obj.updatedBy) : null;
        return new NewsModel({
            uid: obj.uid ?? null,
            title: obj.title ?? null,
            author: obj.author ?? null,
            content: obj.content ?? null,
            createdBy: createdBy,
            updatedBy: updatedBy,
            viewCount: obj.viewCount ?? null
        });
    }

    toJson() {
        let obj = {
            uid: this.uid,
            title: this.title,
            author: this.author,
            content: this.content,
            createdBy: this.createdBy.toString(),
            updatedBy: this.updatedBy.toString(),
            viewCount: this.viewCount
        }
        return JSON.stringify(obj);
    }

    copyWith({ title, author, content, createdBy, updatedBy, viewCount }) {
        return new NewsModel({
            uid: this.uid,
            title: title ?? this.title,
            author: author ?? this.author,
            content: content ?? this.content,
            createdBy: createdBy ?? this.createdBy,
            updatedBy: updatedBy ?? this.updatedBy,
            viewCount: viewCount ?? this.viewCount
        });
    }
}