import React, {useEffect} from 'react';
import TextField from "@mui/material/TextField";
import { Paper, Typography, List } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { StyledBasicHr, StyledBlue1Hr } from "./NewsComponents";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {fetchNews, setPage} from "../../../redux/user_service/news/NewsAction";
import dayjs from "dayjs";
import {NewsModel} from "../../../model/NewsModel";

function NewsListPage() {
    const pageCount = 7;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const format = (date) => dayjs(date).format('YYYY.MM.DD');

    const newsSelector = useSelector((state) => state.news);
    const news = newsSelector.news.map((json, _) => NewsModel.fromJson(json));
    const currentPage = newsSelector.currentPage;
    const start = (currentPage - 1) * pageCount;
    const end = currentPage * pageCount;

    const handlePageChange = (event, value) => {
        dispatch(setPage(value));
    }

    useEffect(() => {
        dispatch(setPage(1));
        dispatch(fetchNews());
    }, [dispatch]);

    return (
        <Box sx={{
            width: '100vw', height: '100vh',
            display: "flex", justifyContent: "center", alignItems: "center",
        }}>
            <Box sx={{width: "1042px", height: "560px", marginTop: "45px"}}>

                <Grid container spacing={2}>
                    <Grid size={6}>
                        <strong style={{fontSize: "21px", textAlign: "left", fontFamily: "Pretendard-Regular"}}>AIIA
                            소식</strong>
                    </Grid>
                    <Grid
                        size={6}
                        sx={{'.MuiFormControl-root': {margin: '16px 0 !important', width: 'auto'}}}
                        style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                        <Link to="/news/edit" style={{ marginRight: 10 }}>
                            <FontAwesomeIcon icon={faPenToSquare} size="2x"/>
                        </Link>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="검색어를 입력해주세요."
                            sx={{'& .MuiOutlinedInput-root': {width: '263px', height: '28px'}}}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <StyledBlue1Hr style={{margin: 0, padding: '10px'}}/>

                <Paper sx={{width: "1042px", height: "415px"}}>
                    <List sx={{padding: 0}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '40px',
                                padding: '10px 20px',
                            }}>
                            <Typography variant="body1" component="div">No</Typography>
                            <Typography variant="body1" component="div">Title</Typography>
                            <Typography variant="body2" color="text.secondary">Author</Typography>
                            <Typography variant="body2" color="text.secondary">Date</Typography>
                            <Typography variant="body2" color="text.secondary">조회수</Typography>
                        </Box>

                        {news.slice(start,end).map((post) => (
                            <React.Fragment key={post.uid}>
                                <Paper
                                    onClick={() => {navigate(`/news/${post.uid}`)}}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '15px 20px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: "#f0f0f0",
                                        },
                                    }}
                                >
                                    <Typography variant="body1" component="div">{post.uid}</Typography>
                                    <Typography variant="body1" component="div">{post.title}</Typography>
                                    <Typography variant="body2" color="text.secondary">{post.author}</Typography>
                                    <Typography variant="body2" color="text.secondary">{format(post.createdBy)}</Typography>
                                    <Typography variant="body2" color="text.secondary">{post.viewCount}</Typography>
                                </Paper>
                                <StyledBasicHr style={{margin:0}}/>
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>

                <Pagination
                    shape="rounded"
                    sx={{
                        marginTop: "30px", display: "flex",
                        justifyContent: "center", alignItems: "center"
                    }}
                    count={Math.ceil(news.length / pageCount)}
                    onChange={handlePageChange}
                />
            </Box>
        </Box>
    );
}

export default NewsListPage;