import {compareDate} from "../../common/Date";

function AdminAttendanceCalendarTileClassName(date, view, counts) {
    if (view !== 'month') return null;
    if(compareDate(date) > 0) return 'none';

    let data = counts[date.getDate()-1];
    let percent = data.value / data.maxValue;

    if (percent === 0) return 'bg-0 blue-border';
    if (percent === 1.0) return 'bg-100';
    if (percent >= 0.75) return 'bg-75';
    if (percent >= 0.5) return 'bg-50';
    return 'bg-25';
}

function UserAttendanceCalendarTileClassName(date, view, statistics) {
    if (view !== 'month') return null;
    if (compareDate(date) > 0) return 'none';

    let data = statistics[date.getDate()-1];
    if (data === 0) return 'present';
    if (data === 1) return 'late';
    if (data === 2) return 'absent blue-border';
    return 'no-active';
}

function ScheduleCalendarTileClassName() {

}

export {
    AdminAttendanceCalendarTileClassName,
    UserAttendanceCalendarTileClassName,
    ScheduleCalendarTileClassName
}