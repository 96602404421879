import {configureStore} from "@reduxjs/toolkit";
import {UserCalendarReducer} from './user_service/calendar/UserCalendarReducer';
import {UserReducer} from "./user_service/user/UserReducer";
import {NewsReducer} from "./user_service/news/NewsReducer";

export default configureStore({
    reducer: {
        user: UserReducer().reducer,
        calendar: UserCalendarReducer().reducer,
        news: NewsReducer().reducer
        // userManagement: UserManagementReducer().reducer
    },
});