import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserModel} from "../../../model/UserModel";

// 유저 액션 (비동기)

const tryLogin = createAsyncThunk(
    'user/tryLogin',
    async (action, _) => {
        try {
            console.log("[User] action: tryLogin (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise(resolve => setTimeout(resolve, 500));
            let result = new UserModel({
                uid: 384924,
                name: "홍길동",
                studentId: "20181234",
                major: "컴퓨터공학과",
                role: "회장",
                unit: "React",
                permission: "admin",
                point: 100
            });

            console.log("[User] action: tryLogin (done)");
            return result.toJson();
        } catch (e) {
            console.log("[User] action: tryLogin (error)");
            console.log(e);
            throw e;
        }
    }
);

const tryLogout = createAsyncThunk(
    'user/tryLogout',
    async (action, _) => {
        try {
            console.log("[User] action: tryLogout (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise(resolve => setTimeout(resolve, 200));

            console.log("[User] action: tryLogout (done)");
            return null;
        } catch (e) {
            console.log("[User] action: tryLogout (error)");
            console.log(e);
        }
    }
);

const changeUserInfo = createAsyncThunk(
    'user/changeUserInfo',
    async (action, _) => {
        try {
            console.log("[User] action: changeUserInfo (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise(resolve => setTimeout(resolve, 200));
            let result = new UserModel({
                point: Math.floor(Math.random() * 1000)
            });

            console.log("[User] action: changeUserInfo (done)");
            return result.toJson();
        } catch (e) {
            console.log("[User] action: changeUserInfo (error)");
            console.log(e);
        }
    }
);

export {
    tryLogin,
    tryLogout,
    changeUserInfo
}