import React, {useState} from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../../../../component/calendar/Calendar.css';
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {AdminAttendanceCalendarTileClassName} from "../../../../component/calendar/TileClassName";
import {AdminAttendanceCalendarTileContent} from "../../../../component/calendar/TileContent";
import {MonthlyAttendanceSample} from "../../../../test/data/AttendanceTestData";

function AttendanceStatisticCalendarPage() {
    const [date, setDate] = useState(new Date());
    const navigator = useNavigate();

    const counts = MonthlyAttendanceSample();

    const handleChange = (date) => {
        setDate(date);
        let formattedDate = dayjs(date).format("YYYYMMDD");
        navigator("/admin/attendance/calendar/daily?date=" + formattedDate);
    };

    return (
        <Calendar
            className="calendar"
            locale ="en-US"
            onChange={handleChange}
            next2Label={null}
            prev2Label={null}
            value={date}
            showNeighboringMonth={false}
            tileClassName={({ activeStartDate, date, view }) =>
                AdminAttendanceCalendarTileClassName(date, view, counts)}
            tileContent={({ date, view}) =>
                AdminAttendanceCalendarTileContent(date, view, counts)}
        />
    );
}

export default AttendanceStatisticCalendarPage;