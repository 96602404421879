import {Route, Routes} from "react-router-dom";
import AdminViewer from "../view/admin_viewer/AdminViewer";
import AttendanceStatisticCalendarPage
    from "../view/admin_viewer/attendance_management/attendance_statistic_calendar_page/AttendanceStatisticCalendarPage";
import AttendanceTablePage from "../view/admin_viewer/attendance_management/attendance_table_page/AttendanceTablePage";
import UserTablePage from "../view/admin_viewer/user_management/user_table_page/UserTablePage";
import UserRegisterPage from "../view/admin_viewer/user_management/user_register_page/UserRegisterPage";
import AttendanceCodeGeneratorPage
    from "../view/admin_viewer/attendance_management/attendacne_code_generator_page/AttendanceCodeGeneratorPage";

export function AdminRoute() {
    return (
        <Routes>
            <Route path="*" element={<div>오류 페이지</div>}/>
            <Route path="" element={<AdminViewer/>}>
                <Route path="attendance" >
                    <Route path="calendar">
                        <Route path="" element={<AttendanceStatisticCalendarPage/>}/>
                        <Route path="daily" element={<AttendanceTablePage/>}/>
                    </Route>
                    <Route path="list" element={<AttendanceTablePage/>}/>
                    <Route path="code" element={<AttendanceCodeGeneratorPage/>}/>
                </Route>
                <Route path="user">
                    <Route path="list" element={<UserTablePage/>}/>
                    <Route path="register" element={<UserRegisterPage/>}/>
                </Route>
            </Route>
        </Routes>
    );
}