import {createSlice} from "@reduxjs/toolkit";
import {addNews, fetchNews, setPage} from "./NewsAction";
import {ActionType} from "../../_ActionType";

export function NewsReducer() {
    return createSlice({
        name: 'news',
        initialState: {
            news: [],
            currentPage: 1,
            status: ActionType.IDLE,
            error: null
        },
        // 비동기 작업 및 외부 액션에 대한 리듀서
        extraReducers: (builder) => {
            // setPage
            builder
                .addCase(setPage, (state, action) => {
                    state.currentPage = action.payload;
                })

            // fetchNews
            builder
                .addCase(fetchNews.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchNews.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.news = [...action.payload];
                })
                .addCase(fetchNews.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                })

            // addNews
            builder
                .addCase(addNews.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(addNews.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.news = action.payload;
                })
                .addCase(addNews.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                })
        }
    })
}