import React, { useState } from 'react';
import { TextField } from '@mui/material';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

function NoteInputForm({
    value,
    onUpdated
}) {
    const [isEditable, setIsEditable] = useState(false);
    const [text, setText] = useState(value);

    const handleEditClick = () => {
        if (isEditable) onUpdated(text);
        setIsEditable(!isEditable);
    };

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
        }}>
            <TextField
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isEditable}
                variant="outlined"
                fullWidth
                InputProps={{
                    style: {
                        backgroundColor: 'white',
                        borderRadius: '8px',
                    },
                }}
            />
            <span style={{ padding: 10 }}>
                <FontAwesomeIcon
                    icon={isEditable ? faCheck : faPenToSquare}
                    onClick={handleEditClick}
                    size={'lg'}
                />
            </span>
        </div>
    );
}

export default NoteInputForm;