import {Col, Row} from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../../../component/calendar/Calendar.css';
import React from "react";
import {UserAttendanceSample} from "../../../test/data/AttendanceTestData";
import {UserAttendanceCalendarTileClassName} from "../../../component/calendar/TileClassName";
import {UserAttendanceCalendarTileContent} from "../../../component/calendar/TileContent";
import Footer from "../../../component/footer/Footer";

function AttendancePage() {
    const [date, setDate] = React.useState(new Date());
    const statistics = UserAttendanceSample();
    console.log(statistics);

    const handleChange = (date) => {
        setDate(date);
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            padding: "calc(250 * var(--bs-aspect-ratio-height)) 0 0 0"
        }}>
            <Row style={{ margin: "0", padding: "0" }}>
                <Col style={{
                    display: "flex",
                    flex: "5",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                    margin: "0"
                }}>
                    <Calendar
                        className="calendar"
                        locale="en-US"
                        onChange={handleChange}
                        next2Label={null}
                        prev2Label={null}
                        value={date}
                        showNeighboringMonth={false}
                        tileClassName={({activeStartDate, date, view}) =>
                            UserAttendanceCalendarTileClassName(date, view, statistics)}
                        tileContent={({date, view}) =>
                            UserAttendanceCalendarTileContent(date, view, statistics)}
                    />
                </Col>
                <Col style={{
                    display: "flex",
                    flex: "4",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    margin: "0",
                    paddingRight: "calc(120 * var(--bs-aspect-ratio-width))",
                }}>
                   <div style={{
                       height: "100%",
                       aspectRatio: "1 / 1",
                       backgroundColor: "white",
                       border: "1px solid black",
                       borderRadius: "10px"
                   }}>

                   </div>
                </Col>
            </Row>

            <Footer/>
        </div>
    );
}

export default AttendancePage;