import {UserSample} from "../../../../test/data/UserTestData";
import DropdownChip from "../../../../component/dropdown/DropdownChip";
import {Col, Row} from "react-bootstrap";
import Paper from "@mui/material/Paper";
import {TableVirtuoso} from "react-virtuoso";
import {VirtuosoTableComponents} from "../../../../component/table/VirtuosoTableComponents";
import {fixedHeaderContent} from "../../../../component/table/FixedHeaderContent";
import {rowContent} from "../../../../component/table/RowContent";
import React from "react";

function UserTablePage() {
    const attributes = [
        { width: "5%", label: 'NO', dataKey: 'id' },
        { width: "10%", label: '이름', dataKey: 'name' },
        { width: "10%", label: '학번', dataKey: 'studentId' },
        { width: "15%", label: '학과', dataKey: 'department' },
        { width: "10%", label: '역할', dataKey: 'role' },
        { width: "15%", label: '유닛', dataKey: 'unit' },
        { width: "15%", label: '권한(출석,일정,뉴스)', dataKey: 'permission' },
        { width: "10%", label: '포인트', dataKey: 'point' }
    ];
    const sample = [...UserSample];

    const setContent = (index, row, attribute) => {
        return attribute.dataKey === 'unit' ? (
            <DropdownChip
                key={`unit${index}`}
                hint="유닛"
                init={
                    row[attribute.dataKey] === "Flutter" ? 0 :
                    row[attribute.dataKey] === "React" ? 1 :
                    row[attribute.dataKey] === "Spring" ? 2 : 3
                }
                items={["Flutter", "React", "Spring", "AI R&D"]}
                onClick={(index) => console.log(index)}
            />
        ) : attribute.dataKey === 'permission' ? (
            <Row>
                {row[attribute.dataKey].map((value, _) => (
                    <Col>
                        <input
                            type="checkbox"
                            checked={value}
                            onChange={(e) => console.log(e.target.checked)}
                            style={{ width: "20px", height: "20px" }}
                        />
                    </Col>
                ))}
            </Row>
        ) : row[attribute.dataKey];
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            padding: "0 calc(88 * var(--bs-aspect-ratio-width))",
            display: "flex",
            flexDirection: "column",
        }}>
            <div>멤버 전체 관리</div>
            <Paper style={{flex: "1 1 auto", marginBottom: 11}}>
                <TableVirtuoso
                    data={sample}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={() => fixedHeaderContent(attributes)}
                    itemContent={(index, row) => rowContent(index, row, attributes, setContent)}
                />
            </Paper>
        </div>
    );
}

export default UserTablePage;