import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";

export function fixedHeaderContent(attributes) {
    return (
        <TableRow>
            {attributes.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{width: column.width}}
                    sx={{backgroundColor: 'background.paper'}}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}