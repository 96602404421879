import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Paper from '@mui/material/Paper';
import {TableVirtuoso} from 'react-virtuoso';
import {AllAttendanceSample, DailyAttendanceSample} from "../../../../test/data/AttendanceTestData";
import {VirtuosoTableComponents} from "../../../../component/table/VirtuosoTableComponents";
import {fixedHeaderContent} from "../../../../component/table/FixedHeaderContent";
import {rowContent} from "../../../../component/table/RowContent";
import DropdownChip from "../../../../component/dropdown/DropdownChip";
import NoteInputForm from "../../../../component/input_form/NoteInputForm";

const setContent = (row, attribute) => {
    return attribute.dataKey === 'status' ? (
        <DropdownChip
            hint="출석"
            init={row[attribute.dataKey] === "출석" ? 0 : row[attribute.dataKey] === "지각" ? 1 : 2}
            items={["출석", "지각", "결석"]}
            onClick={(index) => console.log(index)}
        />
    ) : attribute.dataKey === 'note' ? (
        <NoteInputForm
            value={row[attribute.dataKey]}
            onUpdated={(value) => console.log(value)}
        />
    ) : attribute.dataKey === 'percent' ? (
        row[attribute.dataKey] + '%'
    ) : attribute.dataKey === 'all' ? (
        <Link to={'/admin/attendance/list'}>확인하기</Link>
    ) : row[attribute.dataKey];
}

function AttendanceTablePage() {
    const navigator = useNavigate();
    const location = useLocation();

    let attributes, sample, date;

    if (location.pathname.includes("daily")) {
        const searchParams = new URLSearchParams(location.search);
        date = searchParams.get("date");

        attributes = [
            {width: "10%", label: 'NO', dataKey: 'id'},
            {width: "20%", label: '이름', dataKey: 'name'},
            {width: "20%", label: '출석', dataKey: 'status'},
            {width: "50%", label: '비고', dataKey: 'note'}
        ];
        sample = DailyAttendanceSample();
    } else {
        attributes = [
            {width: "9%", label: 'NO', dataKey: 'id'},
            {width: "13%", label: '이름', dataKey: 'name'},
            {width: "13%", label: '출석횟수', dataKey: 'present'},
            {width: "13%", label: '지각횟수', dataKey: 'late'},
            {width: "13%", label: '결석횟수', dataKey: 'absent'},
            {width: "13%", label: '출석률', dataKey: 'percent'},
            {width: "13%", label: '천체확인', dataKey: 'all'}
        ]
        sample = AllAttendanceSample();
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            padding: "0 calc(88 * var(--bs-aspect-ratio-width))",
            display: "flex",
            flexDirection: "column",
        }}>
            {
                location.pathname.includes("daily") ? (
                    <div>
                        <span
                            style={{padding: 16}}
                            onClick={() => navigator("/admin/attendance/calendar")}>
                            {'<'}
                        </span>
                        <span style={{marginLeft: 12}}>
                            {date.slice(0, 4)}.{date.slice(4, 6)}.{date.slice(6, 8)}
                        </span>
                    </div>
                ) :(
                    <div>멤버 출석률</div>
                )
            }
            <Paper style={{flex: "1 1 auto", marginBottom: 11}}>
                <TableVirtuoso
                    data={sample}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={() => fixedHeaderContent(attributes)}
                    itemContent={(index, row) => rowContent(index, row, attributes, setContent)}
                />
            </Paper>
        </div>
    );
}

export default AttendanceTablePage;