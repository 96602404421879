import React from 'react';
import moment from 'moment';

const CalendarList = ({ events, date }) => {
    const formattedDate = moment(date).format('ddd, D MMM');
    const eventList = events[formattedDate] || [];
    return (
        <div className="event">
            {eventList.map((event, idx) => {
                let { title } = event
                return (
                    <div key={idx}>{String(title).slice(0, 4)}</div>
                );
            })}
        </div>
    );
};

export default CalendarList;
