import React, {useEffect} from "react";
import './SideBar.css';

function SideBar({
    width,
    children,
    padding = "calc(300 * var(--bs-aspect-ratio-height)) 0 0 0"
}) {
    useEffect(() => {
        let timer;
        const el = document.querySelector('.inner');
        const handleScroll = () => {
            el.classList.add('scroll');
            clearTimeout(timer);
            timer = setTimeout(() => {
                el.classList.remove('scroll');
            }, 100);
        };

        el.addEventListener('scroll', handleScroll);

        return () => { el.removeEventListener('scroll', handleScroll) };
    }, []);

    return (
        <div
            className="outer"
            style={{
                width: width,
                height: "100%",
                background: "linear-gradient(270deg, #FFF 50%, rgba(255, 255, 255, 0.50) 100%)",
                boxShadow: "0 0 28px 0 rgba(0, 22, 56, 0.05)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                overflow: "hidden"
            }}>
            <div className="inner" style={{ padding: padding }}>
                {children}
            </div>
        </div>
    );
}

export default SideBar;