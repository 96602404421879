import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {CalendarModel} from "../../../model/CalendarModel";
import moment from "moment";

// 동기 액션
const setSelectedDate = createAction('calendar/setSelectedDate');

// 비동기 액션
const fetchEvents = createAsyncThunk(
    'calendar/fetchEvents',
    async (action) => {
        try {
            console.log("[Calendar] action: fetchEvents (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise((resolve) => setTimeout(resolve, 200));

            let today = new Date();
            let result = {};
            for (let i = 0; i < 5; i++) {
                let rand = () => Math.random() * 20;
                let dummyTitle = "Dummy Event " + i;
                let dummyStart = new Date(today.getTime() + rand() * 1000 * 60 * 60 * 24);
                let dummyEnd = new Date(dummyStart.getTime() + rand() * 1000 * 60 * 60 * 24);
                let diff = dummyEnd.getTime() - dummyStart.getTime();
                diff = Math.floor(diff / (1000 * 60 * 60));

                for (let j=0 ; j < diff ; j++) {
                    let date = new Date(dummyStart.getTime() + j * 1000 * 60 * 60 * 24);
                    let formatted = moment(date).format('ddd, D MMM');
                    let event = new CalendarModel({
                        uid: i,
                        title: dummyTitle,
                        start: date,
                        end: new Date(date.getTime() + rand() * 1000 * 60 * 60),
                    });
                    result[formatted] = [
                        ...(result[formatted] || []),
                        event.toJson()
                    ]
                }
            }

            console.log(`[Calendar] action: fetchEvents (done)`);
            return JSON.stringify(result);
        } catch (e) {
            console.log("[Calendar] action: fetchEvents (error)");
            console.log(e);
        }
    }
);

const addEvent = createAsyncThunk(
    'calendar/addEvent',
    async (action) => {
        try {
            console.log("[Calendar] action: addEvent (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise((resolve) => setTimeout(resolve, 200));

            console.log("[Calendar] action: addEvent (done)");
            return action;
        } catch (e) {
            console.log("[Calendar] action: addEvent (error)");
            console.log(e);
        }
    }
);

const updateEvents = createAsyncThunk(
    'calendar/updateEvents',
    async (action) => {
        try {
            console.log("[Calendar] action: updateEvents (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise((resolve) => setTimeout(resolve, 200));

            console.log("[Calendar] action: updateEvents (done)");
            return action;
        } catch (e) {
            console.log("[Calendar] action: updateEvents (error)");
            console.log(e);
        }
    }
);

export {
    setSelectedDate,
    fetchEvents,
    addEvent,
    updateEvents
};